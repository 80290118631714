.sidebar {
  position: fixed;
  z-index: 1;
  height: 100%;
  width: 320px;

  color: white;
  background: rgb(25, 25, 25);

  border-right: 5px solid gray;
  overflow-y: scroll;
}

.sidebar button {
  margin-bottom: 10px;
}

.sidebar .stat {
  margin-bottom: 5px;
  padding-bottom: 5px;
  border-bottom: 1px solid;
}

.sidebar .disclaimer {
  margin-top: 20px;
  font-size: 12px;
}
